<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Otros - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select disabled placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchClients" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro :">
                    <b-form-input disabled class="text-center" v-model="other.number"></b-form-input>
                    <small v-if="errors.number" class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo Movimiento:">
                    <b-form-select disabled v-model="other.movement_type" :options="movement_type"></b-form-select>
                    <small v-if="errors.total" class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Importe:">
                    <b-form-input disabled type="number" class="text-right" step="any" v-model="other.total"></b-form-input>
                    <small v-if="errors.total" class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Descripción:">
                    <b-form-textarea disabled type="text" v-model="other.description"></b-form-textarea>
                  </b-form-group>
                </b-col>

                

                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="DataPrint" class="form-control" variant="warning" ><i class="fas fa-print"></i> Imprimir Ticket</b-button>
                </b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/otros/listar' }" append >REGRESAR</b-link>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_other"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Other',
      role: 3,
      other: {
          id_other:'',
          id_partner:'',
          id_user:'',
          number:'',
          movement_type: 1,
          payment_method: '008',
          description:'',
          broadcast_date:'',
          total:(0).toFixed(2),
          state:1,
      },
      movement_type:[
        {value:1,text:'Ingreso'},
        {value:2,text:'Salida'},
      ],
      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      partners: [],
      partner:null,
      //errors
      errors: {
        id_partner: false,
        description: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewOther();
  },
  methods: {
    EditOther,
    Validate,
    ViewOther,
    SearchClients,
    modalClients,

     DataPrint,
    Print,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}

function ViewOther() {
  let id_other = je.decrypt(this.id_other);
  let me = this;
  let url = me.url_base + "other/view/"+id_other;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.other.id_other = response.data.result.id_other;
        me.other.id_partner = response.data.result.id_partner;
        me.other.id_user = response.data.result.id_user;
        me.other.number = response.data.result.number;
        me.other.payment_method = response.data.result.payment_method;
        me.other.description = response.data.result.description;
        me.other.movement_type = response.data.result.movement_type;
        me.other.broadcast_date = response.data.result.broadcast_date;
        me.other.total = response.data.result.total;
        me.other.state = response.data.result.state;

        me.partner = {id: response.data.result.id_partner, full_name:response.data.result.partner_name +" - "+response.data.result.partner_document_number };
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditOther() {
  let me = this;
  me.other.id_user = me.user.id_user;
  me.other.id_partner = me.partner.id;
  let url = me.url_base + "other/edit";
  let data = me.other;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.number = this.other.number.length == 0 ? true : false;
  this.errors.movement_type = this.other.movement_type.length == 0 ? true : false;
  this.errors.total = this.other.total.length == 0 ? true : false;
  
  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.movement_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar Otros ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditOther();
    }
  });
}

function DataPrint() {
  let me = this;
  let id_other = je.decrypt(this.id_other);
  let url = me.url_base + "other/data-print/"+id_other;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result)
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}

</script>
